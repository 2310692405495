import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledCaseStudy = styled.section`
	width: 100%;
	position: relative;
	padding: 0;
	border-bottom-width: 0;
	overflow: hidden;

	${breakpoint.medium`
		padding: 0;
	`}

	  ${Container} {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		margin: 60px auto 80px;
	
	  ${breakpoint.small`
		margin: 100px auto 150px;
	  `}
	}
  
  	h1 {
  		font-size: 18px;
  		text-transform: uppercase;
  		color: ${colors.purpleDark};
  		margin-bottom: 5px;
  		
		  ${breakpoint.small`
				font-size: 22px;
		  `}
  	}
  	
  	h2 {
  		font-size: 24px;
  		margin-bottom: 5px;

	  ${breakpoint.small`
  			font-size: 32px;
	  `}
  	}
  	  	
  a {
    color: ${colors.orange};

    &:hover,
    &:active {
      color: ${colors.purpleDark};
    }
  }
  	
`

const StyledFrame = styled.div`
  width: 100%;
  min-height: 300px;
  height: 100%;
  position: relative;

	  ${breakpoint.small`
		min-height: 500px;
	  `}

	  ${breakpoint.medium`
		min-height: 800px;
	  `}
    }

	iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
`

const CaseStudy = props => {
	return (
		<StyledCaseStudy>
			<StyledFrame>
				<iframe
					title={props.title}
					src={props.src}
					frameBorder="0"
					allow="autoplay; fullscreen"
				></iframe>
			</StyledFrame>
			<Container>
				<h1>Case Study</h1>
				<h2>{props.title}</h2>
				<p>{props.bodytxt}</p>
			</Container>
		</StyledCaseStudy>
	)
}
export default CaseStudy
